:root {
  --primary: #10b981;
  --primary-dark: #0d9868;
  --secondary: #3b82f6;
  --text: #333333;
  --text-light: #6b7280;
  --background: #f9fafb;
  --card-bg: #ffffff;
  --border: #e5e7eb;
  --error: #ef4444;
  --warning: #f59e0b;
  --success: #10b981;
  --sidebar-bg: #1f2937;
  --sidebar-text: rgba(255, 255, 255, 0.7);
  --header-height: 64px;
  --sidebar-width: 250px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  color: var(--text);
  background-color: var(--background);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-page {
  padding-top: var(--header-height);
}

.main-content {
  display: flex;
  flex: 1;
}

.content-area {
  flex: 1;
  padding: 1.5rem;
  margin-left: var(--sidebar-width);
  min-height: calc(100vh - var(--header-height));
  background-color: var(--background);
}

@media (max-width: 768px) {
  .content-area {
    margin-left: 0;
    padding: 1rem;
  }
  
  :root {
    --sidebar-width: 0px;
  }
}

/* Button styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.btn-primary {
  background-color: var(--primary);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-dark);
}

.btn-secondary {
  background-color: white;
  border-color: var(--border);
  color: var(--text);
}

.btn-secondary:hover {
  background-color: var(--background);
}

.btn-danger {
  background-color: var(--error);
  color: white;
}

.btn-danger:hover {
  opacity: 0.9;
}

/* Card styles */
.card {
  background-color: var(--card-bg);
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--border);
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

/* Form styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--border);
  border-radius: 0.375rem;
  background-color: white;
  font-size: 1rem;
}

.form-control:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.2);
}

/* Grid layout */
.grid {
  display: grid;
  gap: 1.5rem;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1200px) {
  .grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .grid-3, .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-2, .grid-3, .grid-4 {
    grid-template-columns: 1fr;
  }
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

/* Risk score colors */
.risk-low {
  color: #10b981;
}

.risk-medium {
  color: #f59e0b;
}

.risk-high {
  color: #ef4444;
}

.bg-risk-low {
  background-color: rgba(16, 185, 129, 0.1);
}

.bg-risk-medium {
  background-color: rgba(245, 158, 11, 0.1);
}

.bg-risk-high {
  background-color: rgba(239, 68, 68, 0.1);
}
